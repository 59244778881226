.abhalist-screen-container {
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.abdm-item {
  border: 1px solid var(--GRAY_20);
  border-radius: 12px;
  background-color: var(--GRAY_0);
  padding: 12px 16px;
  width: "100%";
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.abdm-item-list-container {
  display: flex;
  gap: 24px;
  flex-direction: column;
  overflow-y: scroll;
  max-height: 70vh;
}

.abdm-item-download-icon {
  height: 24px;
  width: 24px;
  color: var(--ORANGE_60);
}

.focused {
  background-color: #f152231a;
  border: 1px solid var(--ORANGE_60);
}

.right-arrow {
  color: var(--ORANGE_60);
  font-size: 20px;
}

.abhalist-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
