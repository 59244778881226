.tnc-modal-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.tnc-modal-popup-content {
  max-width: 326px;
  padding: 16px;
  border-radius: 16px;
  margin: 0px 32px;
  background-color: var(--GRAY_0);
}

.tnc-header-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.tnc-modal-close-icon {
  font-size: 20px;
  color: var(--TEXT_PRIMARY);
  cursor: pointer;
}
