.new-abha-address-screen-container {
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow-y: auto;
  max-height: calc(100vh - 196px);
}

.abha-form-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.suggestion-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px;
  margin: 8px 6px;
}

.abha-id-suggestion-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 6px 16px;
  border-radius: 24px;
  border: 1px solid var(--GRAY_30);
}

.abha-id-selected-suggestion {
  background-color: #f152231a;
  border: 1px solid var(--ORANGE_60);
}

.x-icon {
}

.rule-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.checkmark-icon {
  color: var(--GREEN_60);
}

.abha-validation-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
}

.new-abha-address-btn-container {
  width: calc(100dvw - 32px);
  max-width: 448px;
  background-color: var(--GRAY_0);
  padding: 12px 16px;
  border-top: 1px solid var(--TEXT_DISABLED);
  position: absolute;
  bottom: 0;
}
