.container {
  background-color: var(--GRAY_0);
}

.children-container {
  margin-top: 88px;
}

.back-container {
  width: calc(100dvw - 32px);
  max-width: 448px;
  padding: 12px 16px 12px 16px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  position: fixed;
  top: 40px;
  z-index: 1000;
}

.back-arrow {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
