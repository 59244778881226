.screen-container {
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: var(--GRAY_0);
}

.row-text {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.otp-input-container {
  width: calc(100dvw - 32px);
  max-width: 448px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.input-style {
  border: 1px solid var(--TEXT_DISABLED);
  height: 24px;
  width: 14px;
  padding: 10px 16px;
  border-radius: 8px;
  font-family: "Manrope";
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  outline: none;
  color: var(--TEXT_PRIMARY);
}

.otp-error-input-style {
  border: 1.5px solid var(--RED_60);
}

.input-style:focus {
  border: 1px solid var(--ORANGE_60);
}

.input-style-aadhar {
  border: 1px solid var(--TEXT_DISABLED);
  height: 22px;
  width: 12px;
  padding: 10px 12px;
  border-radius: 8px;
  font-family: "Manrope";
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  outline: none;
  color: var(--TEXT_PRIMARY);
}

.error-otp-input-style-aadhar {
  border: 1.5px solid var(--RED_60);
}

.input-style-aadhar:focus {
  border: 1px solid var(--ORANGE_60);
}

.aadhar-content-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 14px;
}

.aadhar-input-gap {
  display: flex;
  flex-direction: column;
  gap: 70px;
}

.aadhar-stepper-image {
  width: 36px;
  height: 170px;
}

.aadhar-mobile-input-container {
  width: calc(100dvw - 82px);
  max-width: 398px;
  height: 115px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.input-icon {
  height: 24px;
  width: 24px;
}

.otp-btn-container {
  width: 100dvw;
  max-width: 480px;
  position: absolute;
  bottom: 0;
  padding: 8px 0px;
  border-top: 0.5px solid var(--GRAY_30);
}
