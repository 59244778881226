.session-timer-text {
  background-color: var(--PRIMARY_40);
  height: 40px;
  width: 100dvw;
  max-width: 480px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  font-family: "Manrope";
  z-index: 1000;
}

.session-expired-alert-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.session-expired-alert-container {
  padding: 16px;
  margin: 16px;
  border-radius: 16px;
  background-color: var(--GRAY_0);
  display: flex;
  flex-direction: column;
  gap: 16px;
}
