.aadhar-container {
  border-width: 0px 1.5px 1.5px 0px;
  border-style: solid;
  border-color: #9d9dc6;
  border-radius: 16px;
  width: calc(100dvw - 32px);
  max-width: 446px;
}

.aadhar-header {
  background-color: var(--GRAY_0);
  border-radius: 16px 16px 0px 0px;
  padding: 16px 20px 6px 20px;
}

.aadhar-details {
  height: 0.42 * calc(100dvw - 72px);
  width: calc(100dvw - 72px);
  max-width: 406px;
}

.aadhar-footer {
  background-color: var(--YELLOW_10);
  height: 106px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-radius: 0px 0px 16px 16px;
  justify-content: center;
  align-items: center;
}

.triple-input-container {
  display: flex;
  gap: 4px;
}

.input {
  padding: 8px 12px;
  width: calc((100dvw - 158px) / 3);
  max-width: 96px;
  height: 38px;
  border: 1px solid var(--TEXT_DISABLED);
  border-radius: 10px;
  outline: none;
  font-family: "Manrope";
  font-weight: 700;
  color: var(--BLACK);
  text-align: center;
  font-size: 14px;
  line-height: 22px;
}

.focused {
  outline: 1px solid var(--ORANGE_60);
}
