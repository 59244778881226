.login-page-container {
  background-color: var(--PRIMARY_10);
  display: flex;
  flex-direction: column;
  height: calc(100dvh - 48px);
}

.top-app-bar {
  padding: 12px 16px 12px 16px;
  display: flex;
  align-items: flex-start;
}

.tatvacare-logo {
  width: 91px;
  height: 24px;
}

.form-container {
  padding: 24px 16px 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.tnc-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}

.tnc-icon-container {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tnc-icon {
  height: 18px;
  width: 18px;
  color: var(--ORANGE_60);
}

.disabled-arrow-icon {
  color: var(--TEXT_DISABLED);
}

.enabled-arrow-icon {
  color: var(--GRAY_0);
}

.input-icon {
  color: var(--TEXT_TERTIARY);
}
