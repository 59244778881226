.profile-page-container {
  background-color: var(--GRAY_0);
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100dvw;
}

.profile-loader-container {
  background-color: var(--GRAY_0);
  width: 390px;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.top-sheet {
  background-color: var(--PRIMARY_20);
  padding: 24px 0px;
  border-radius: 0px 0px 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.icon {
  height: 20px;
  width: 20px;
  color: var(--ORANGE_60);
}

.other-information-container {
  background-color: var(--GRAY_0);
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.other-information {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.other-info-item-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}

.other-info-detail {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.divider-line {
  background-color: var(--GRAY_20);
  width: 336px;
  height: 1px;
  align-self: center;
}

.create-new-abha-container {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.edit-info-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.carousel .slider-wrapper {
  height: 280px !important;
}

.control-dots {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
