body {
  margin: 0;
  height: 100vh;
  font-family: "Manrope", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --PRIMARY_10: #faf6ff;
  --PRIMARY_20: #f6edff;
  --PRIMARY_30: #eddaff;
  --PRIMARY_40: #e3c8ff;
  --PRIMARY_50: #dab5ff;
  --PRIMARY_60: #d1a3ff;
  --PRIMARY_70: #a782cc;
  --PRIMARY_80: #7d6299;
  --PRIMARY_90: #544166;
  --PRIMARY_100: #2a2133;

  --ORANGE_10: #feeee9;
  --ORANGE_20: #fcdcd3;
  --ORANGE_30: #f9baa7;
  --ORANGE_40: #f7977b;
  --ORANGE_50: #f4754f;
  --ORANGE_60: #f15223;
  --ORANGE_70: #c1421c;
  --ORANGE_80: #913115;
  --ORANGE_90: #60210e;
  --ORANGE_100: #301007;

  --YELLOW_10: #fefbeb;
  --YELLOW_20: #fdf7d7;
  --YELLOW_30: #fbefaf;
  --YELLOW_40: #f9e786;
  --YELLOW_50: #f7df5e;
  --YELLOW_60: #f5d736;
  --YELLOW_70: #c4ac2b;
  --YELLOW_80: #938120;
  --YELLOW_90: #625616;
  --YELLOW_100: #312b0b;

  --GREEN_10: #edf7ed;
  --GREEN_20: #dbefdc;
  --GREEN_30: #b7dfb9;
  --GREEN_40: #94cf96;
  --GREEN_50: #70bf73;
  --GREEN_60: #4caf50;
  --GREEN_70: #3d8c40;
  --GREEN_80: #2e6930;
  --GREEN_90: #1e4620;
  --GREEN_100: #0f2310;

  --RED_10: #fceded;
  --RED_20: #fadada;
  --RED_30: #f5b6b6;
  --RED_40: #ef9191;
  --RED_50: #ea6d6d;
  --RED_60: #e54848;
  --RED_70: #b73a3a;
  --RED_80: #892b2b;
  --RED_90: #5c1d1d;
  --RED_100: #0f0505;

  --GRAY_0: #ffffff;
  --GRAY_10: #f2f2f2;
  --GRAY_20: #e4e7eb;
  --GRAY_30: #bac6d2;
  --GRAY_40: #9bb1c8;
  --GRAY_50: #6b7b8c;
  --GRAY_60: #5a6774;
  --GRAY_70: #3d4854;
  --GRAY_80: #1f2933;

  --TEXT_PRIMARY: #1f2933;
  --TEXT_SECONDARY: #3d4854;
  --TEXT_TERTIARY: #5a6774;
  --TEXT_DISABLED: #9bb1c8;

  --BLACK: #000000;
}
