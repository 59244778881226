.AbhaCard-card-container {
  max-width: 448px;
  margin: 0px 16px;
}

.AbhaCard-card-header-container {
  height: 56px;
  max-width: 448px;
  background-color: #283993;
  display: flex;
  padding: 12px 16px;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px 16px 0px 0px;
}

.AbhaCard-nha-logo {
  max-width: 74px;
  height: 32px;
}

.AbhaCard-ayushman-logo {
  max-width: 32px;
  height: 32px;
}

.AbhaCard-card-body-container {
  background-color: var(--GRAY_0);
  border-radius: 0px 0px 16px 16px;
}

.AbhaCard-card-body-header-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 14px;
  padding: 12px;
}

.AbhaCard-profile-picture {
  height: 60px;
  max-width: 60px;
  border-radius: 4px;
  background-color: darkseagreen;
}

.AbhaCard-user-details-container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.AbhaCard-qr {
  height: 44px;
  max-width: 44px;
}

.AbhaCard-separator {
  height: 1px;
  background-color: var(--GRAY_20);
  margin: 0px 12px;
}

.AbhaCard-card-body-footer-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  padding: 12px;
}

.AbhaCard-card-body-footer-item-container {
  height: 38px;
  width: 124px;
}
