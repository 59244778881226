.input-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.text-input-container {
  height: 30px;
  border-radius: 10px;
  background-color: var(--GRAY_0);
  padding: 8px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.focused-input {
  border: 1px solid var(--ORANGE_60);
}

.unfocused-input {
  border: 1px solid var(--GRAY_30);
}

.input-field-error {
  border: 1px solid var(--RED_60);
}

.text-input {
  border: 0;
  outline: none;
  width: 100%;
  color: var(--TEXT_PRIMARY);
  font-family: "Manrope";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
}

.text-input:focus {
  outline: none !important;
}

.text-input::placeholder {
  font-family: "Manrope";
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.prefix {
  color: var(--TEXT_PRIMARY);
  font-family: "Manrope";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
}

.suffix {
  color: var(--TEXT_TERTIARY);
  font-family: "Manrope";
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: right;
}
