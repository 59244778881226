.App {
  text-align: center;
  height: 100dvh;
  width: 100dvw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--GRAY_10);
  overflow: hidden auto;
}

.App-View-Container {
  max-width: 480px;
  width: 100dvw;
  height: 100dvh;
  background-color: var(--GRAY_0);
}

.app-loader-container {
  height: 100dvh;
  width: 100dvw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--GRAY_0);
}
