.alert-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1000;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.alert {
  position: relative;
  padding: 16px;
  background-color: #e54848;
  color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  animation-duration: 0.3s;
  animation-fill-mode: both;
  width: 358px;
  max-height: 80px;
  overflow: hidden;
}

.alert-content {
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Manrope";
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

/* Animation keyframes */
@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideOutToTop {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

/* Apply animations based on visibility */
.alert-container.slide-in {
  opacity: 1;
  pointer-events: auto;
  animation: slideInFromTop 0.3s ease-in-out;
}

.alert-container.slide-out {
  opacity: 0;
  pointer-events: none;
  animation: slideOutToTop 0.3s ease-in-out;
}
