.success-modal-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--GRAY_10);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.success-modal-popup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 0px 32px;
  align-items: center;
  max-width: 416px;
  background-color: var(--GRAY_0);
  height: 100%;
}

.success-modal-animation-container {
  height: 100px;
  width: 100px;
}
