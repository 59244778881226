.screen-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 16px;
}

.input-icon {
  height: 24px;
  width: 24px;
}

.btn-container {
  width: calc(100dvw - 32px);
  max-width: 448px;
  border-top: 1px solid var(--TEXT_DISABLED);
  position: absolute;
  bottom: 0;
  padding: 8px 16px;
}
